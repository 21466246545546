import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { EmptyObject } from '@ember/component/helper';
import { action } from '@ember/object';
import { on } from '@ember/modifier';
import { fn } from '@ember/helper';
import rideCompletionStatuses, { type StatusState } from '../../utils/data/ride-completion-status';
import RideStatusInfo from './ride-status-info';
import type UiFormGroupComponent from '@onwardcare/ui-components/components/ui-form/group';
export interface RideStatusFilterSignature {
    Element: HTMLDivElement;
    Args: {
        // TODO: Need to figure out how to properly use the `UiForm` block types.
        form: {
            Group: typeof UiFormGroupComponent;
        };
        rideStatusIds: string[];
        onChange: (statuses: string[]) => unknown;
        state?: StatusState;
    };
    Blocks: EmptyObject;
}
export default class RideStatusFilterComponent extends Component<RideStatusFilterSignature> {
    get rideStatusDisplay() {
        const rideStatuses1 = rideCompletionStatuses.filter((rideStatus1)=>this.args.rideStatusIds.includes(rideStatus1.id));
        return rideStatuses1.length > 0 ? rideStatuses1.map((rideStatus1)=>rideStatus1.name).join(', ') : null;
    }
    get rideStatuses() {
        if (this.args.state === undefined) {
            return rideCompletionStatuses;
        }
        return rideCompletionStatuses.filter((status1)=>status1.state === this.args.state);
    }
    @action
    toggleRideStatus(state1: StatusState) {
        const statuses1 = rideCompletionStatuses.filter((rideStatus1)=>rideStatus1.state === state1).map((rideStatus1)=>rideStatus1.id);
        this.args.onChange(statuses1);
    }
    static{
        template(`
    <@form.Group data-test-id='ride-status' as |Group|>
      <Group.Label class='flex gap-1 items-center'>
        Ride Status

        <RideStatusInfo @rideCompletionStatuses={{this.rideStatuses}} />
      </Group.Label>
      <Group.Listbox
        @multiple={{true}}
        @value={{@rideStatusIds}}
        @onChange={{@onChange}}
        as |listbox|
      >
        <listbox.Button @placeholder='All Ride Statuses'>
          {{this.rideStatusDisplay}}
        </listbox.Button>

        {{#each this.rideStatuses as |rideStatus|}}
          <listbox.Option @value={{rideStatus.id}}>
            {{rideStatus.name}}
          </listbox.Option>
        {{/each}}
      </Group.Listbox>

      {{#unless @state}}
        <div class='mt-1 flex items-center gap-2 text-xs'>
          <span class='text-gray-500'>Select:</span>
          <ul class='flex gap-2'>
            <li>
              <button
                type='button'
                data-test-id='upcoming'
                class='text-xs text-blue-500 underline hover:no-underline'
                {{on 'click' (fn this.toggleRideStatus 'upcoming')}}
              >
                All Upcoming
              </button>
            </li>
            <li>
              <button
                type='button'
                data-test-id='historical'
                class='text-xs text-blue-500 underline hover:no-underline'
                {{on 'click' (fn this.toggleRideStatus 'historical')}}
              >
                All Historical
              </button>
            </li>
          </ul>
        </div>
      {{/unless}}
    </@form.Group>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Filters::RideStatusFilter': typeof RideStatusFilterComponent;
    }
}
