import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type SessionService from '../../services/onward-session';
import type Transition from '@ember/routing/transition';
import { isPresent } from '@ember/utils';
import { arrayToString } from '../../utils/serialize';
import type { AccountType } from '../../components/filters/account-filter';
import { dateRanges as RequestedStartTimeDateRanges } from '../../components/filters/requested-start-time-filter';
import { dateRanges as BookTimeDateRanges } from '../../components/filters/book-time-filter';
import {
  buildDateRangeFilter,
  type FilterDateRange,
} from '../../utils/filters/date-range';
import definedRideCompletionStatuses, {
  type StatusState,
} from '../../utils/data/ride-completion-status';

export default class RidesRoute extends Route {
  @service declare router: RouterService;
  @service('onward-session') declare session: SessionService;

  async beforeModel(transition: Transition) {
    if (this.session.isOnwardAssociate === false) {
      return this.router.replaceWith('access-denied');
    }
  }
}

export type RidesPage = 'all' | 'upcoming' | 'past';

export type RidesQueryParams = {
  accountIds: string[];
  accountType: string | null;
  bookTimePeriod: string | null;
  bookTimeStart: string | null;
  bookTimeEnd: string | null;
  distanceIds: string[];
  financialResponsibilities: string[];
  geoId: string | null;
  page: number;
  regionIds: string[];
  requestedStartTimePeriod: string | null;
  requestedStartTimeStart: string | null;
  requestedStartTimeEnd: string | null;
  rideCompletionStatuses: string[];
  rideTypeIds: string[];
  search: string;
  sort: string | null;
  transportPartnerIds: string[];
  transportTypeIds: string[];
  zipcodeIds: string[];
};

export const queryParams = {
  accountIds: { refreshModel: true },
  accountType: { refreshModel: true },
  bookTimePeriod: { refreshModel: true },
  bookTimeStart: { refreshModel: true },
  bookTimeEnd: { refreshModel: true },
  distanceIds: { refreshModel: true },
  financialResponsibilities: { refreshModel: true },
  geoId: { refreshModel: true },
  page: { refreshModel: true },
  regionIds: { refreshModel: true },
  requestedStartTimePeriod: { refreshModel: true },
  requestedStartTimeStart: { refreshModel: true },
  requestedStartTimeEnd: { refreshModel: true },
  rideCompletionStatuses: { refreshModel: true },
  rideTypeIds: { refreshModel: true },
  search: { refreshModel: true },
  sort: { refreshModel: true },
  transportPartnerIds: { refreshModel: true },
  transportTypeIds: { refreshModel: true },
  zipcodeIds: { refreshModel: true },
};

export interface RidesFilters {
  channel?: string;
  parent_account_id?: string;
  account_id?: string;
  created_at?: string | object;
  payer_type?: string;
  geo_id?: string;
  region_id?: string;
  requested_start_time?: string | object;
  ride_completion_status?: string;
  ride_type?: string;
  transport_partner_id?: string;
  transport_type_id?: string;
  zipcode_id?: string;
  distance_range?: string;
  search?: string;
}

export function convertRidesPageToState(
  page: RidesPage,
): StatusState | undefined {
  switch (page) {
    case 'all':
      return undefined;
    case 'upcoming':
      return 'upcoming';
    case 'past':
      return 'historical';
  }
}

export function buildFilterFromQueryParams(
  params: RidesQueryParams,
  page: RidesPage = 'all',
) {
  let filter: RidesFilters = {};
  const {
    accountIds,
    accountType,
    bookTimePeriod,
    bookTimeStart,
    bookTimeEnd,
    distanceIds,
    financialResponsibilities,
    geoId,
    regionIds,
    requestedStartTimePeriod,
    requestedStartTimeStart,
    requestedStartTimeEnd,
    rideCompletionStatuses,
    rideTypeIds,
    search,
    transportPartnerIds,
    transportTypeIds,
    zipcodeIds,
  } = params;

  if (accountType) {
    const convertedType = accountType as AccountType;

    switch (convertedType) {
      case 'b2b':
      case 'b2c':
        filter = {
          ...filter,
          channel: convertedType === 'b2c' ? 'b2c,b2b2c' : 'b2b',
        };
        break;
      case 'parent':
        filter = { ...filter, parent_account_id: arrayToString(accountIds) };
        break;
      case 'selected':
        filter = { ...filter, account_id: arrayToString(accountIds) };
        break;
    }
  }

  if (isPresent(bookTimePeriod)) {
    filter = {
      ...filter,
      created_at: buildDateRangeFilter(
        {
          datePeriod: bookTimePeriod,
          startDate: bookTimeStart,
          endDate: bookTimeEnd,
        } as FilterDateRange,
        BookTimeDateRanges,
      ),
    };
  }

  if (isPresent(financialResponsibilities)) {
    filter = {
      ...filter,
      payer_type: arrayToString(financialResponsibilities),
    };
  }

  if (geoId) {
    filter = { ...filter, geo_id: geoId };
  }

  if (isPresent(regionIds)) {
    filter = { ...filter, region_id: arrayToString(regionIds) };
  }

  if (isPresent(requestedStartTimePeriod)) {
    filter = {
      ...filter,
      requested_start_time: buildDateRangeFilter(
        {
          datePeriod: requestedStartTimePeriod,
          startDate: requestedStartTimeStart,
          endDate: requestedStartTimeEnd,
        } as FilterDateRange,
        RequestedStartTimeDateRanges,
      ),
    };
  }

  if (isPresent(rideCompletionStatuses)) {
    let cleanedStatuses = rideCompletionStatuses;

    // If the user is on the upcoming or historical pages, we want to make sure
    // that there are no invalid statuses in the filter. This will remove any
    // invalid statuses.
    if (page !== 'all') {
      const state = convertRidesPageToState(page);
      cleanedStatuses = rideCompletionStatuses.filter((status) => {
        return (
          definedRideCompletionStatuses.find((s) => s.id === status)?.state ===
          state
        );
      });
    }

    if (cleanedStatuses.length === 0) {
      delete filter.ride_completion_status;
    } else {
      filter = {
        ...filter,
        ride_completion_status: arrayToString(cleanedStatuses),
      };
    }
  } else {
    // If the user is on the upcoming or historical page and they have no status
    // filters applied, then make sure we apply all the statuses related to the
    // page.
    if (page !== 'all') {
      const state = convertRidesPageToState(page);
      filter = {
        ...filter,
        ride_completion_status: arrayToString(
          definedRideCompletionStatuses
            .filter((status) => status.state === state)
            .map((status) => status.id),
        ),
      };
    }
  }

  if (isPresent(rideTypeIds)) {
    filter = {
      ...filter,
      ride_type: arrayToString(rideTypeIds),
    };
  }

  if (isPresent(transportPartnerIds)) {
    filter = {
      ...filter,
      transport_partner_id: arrayToString(transportPartnerIds),
    };
  }

  if (isPresent(transportTypeIds)) {
    filter = {
      ...filter,
      transport_type_id: arrayToString(transportTypeIds),
    };
  }

  if (isPresent(zipcodeIds)) {
    filter = { ...filter, zipcode_id: arrayToString(zipcodeIds) };
  }

  if (isPresent(distanceIds)) {
    filter = {
      ...filter,
      distance_range: arrayToString(distanceIds),
    };
  }

  if (isPresent(search)) {
    filter = { ...filter, search };
  }

  return filter;
}
