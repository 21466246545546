import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type SessionService from '../../services/onward-session';
import type Transition from '@ember/routing/transition';

export default class AdminRoute extends Route {
  @service declare router: RouterService;
  @service('onward-session') declare session: SessionService;

  async beforeModel(transition: Transition) {
    // The Vectorcare page moved out of the Admin module, so if a user is trying
    // to go to the old link, redirect them to the new.
    if (transition.to?.name === 'authenticated.admin.vectorcare') {
      return this.router.replaceWith('authenticated.vectorcare');
    }

    if (this.session.isAdmin === false) {
      return this.router.replaceWith('access-denied');
    }
  }
}
