import Controller from '@ember/controller';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type { Resolved } from 'types';
import type RideRoute from 'onward-portal/routes/authenticated/rides/details';
import type SessionService from '../../../services/onward-session';
import type { RidesPage } from '../../../routes/authenticated/rides';

export default class RideDetailsController extends Controller {
  declare model: Resolved<ReturnType<RideRoute['model']>>;

  @service('onward-session') declare session: SessionService;

  queryParams = ['from'];

  @tracked from: RidesPage = 'all';

  get fromInfo() {
    switch (this.from) {
      case 'upcoming':
        return {
          route: 'authenticated.rides.upcoming',
          title: 'Upcoming Rides',
        };

      case 'past':
        return { route: 'authenticated.rides.past', title: 'Past Rides' };

      case 'all':
      default:
        return { route: 'authenticated.rides.index', title: 'All Rides' };
    }
  }
}
