import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type Store from '@ember-data/store';
import type Transition from '@ember/routing/transition';
import type PastRidesController from '../../../controllers/authenticated/rides/past';
import {
  buildFilterFromQueryParams,
  queryParams,
  type RidesQueryParams,
} from '../rides';

export default class PastRidesRoute extends Route {
  @service declare store: Store;

  @tracked isEnteringRoute = true;

  queryParams = queryParams;

  async model(params: RidesQueryParams) {
    const { page, sort } = params;
    const filter = buildFilterFromQueryParams(params, 'past');
    let options: Record<string, string | number | object> = {
      include:
        'account.parent_account,driver.transport_partner,rider,transport_type',
      page: {
        number: page,
      },
      filter,
    };

    if (sort) {
      options = { ...options, sort };
    }

    const rides = await this.store.query('ride', options);

    return {
      rides: rides.slice(),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Figure out how to type this.
      meta: rides.meta,
    };
  }

  @action
  didTransition() {
    // Animate scrolling to the top of the page when params change.
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  @action
  deactivate() {
    // This is needed so that we show the route loading spinner if the user
    // comes back to the page later.
    this.isEnteringRoute = true;
  }

  @action
  loading(transition: Transition) {
    if (this.isEnteringRoute) {
      this.isEnteringRoute = false;
      return true;
    }

    const controller = <PastRidesController>(
      // eslint-disable-next-line ember/no-controller-access-in-routes
      this.controllerFor(this.routeName)
    );
    controller.isLoading = true;

    transition.finally(() => {
      controller.isLoading = false;
    });
  }
}
