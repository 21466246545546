import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type { Resolved } from 'types';
import type { AccountType } from '../../../components/filters/account-filter';
import type {
  AllRidesGridParams,
  AllRidesOptions,
} from '../../../components/rides/all-rides-grid';
import type PastRidesRoute from '../../../routes/authenticated/rides/past';
import type { Filters } from '../../../components/rides/filters';

export default class PastRidesController extends Controller {
  declare model: Resolved<ReturnType<PastRidesRoute['model']>>;

  @service declare router: RouterService;

  queryParams = [
    'accountIds',
    'accountType',
    'bookTimePeriod',
    'bookTimeStart',
    'bookTimeEnd',
    'distanceIds',
    'financialResponsibilities',
    'geoId',
    'page',
    'regionIds',
    'requestedStartTimePeriod',
    'requestedStartTimeStart',
    'requestedStartTimeEnd',
    'rideCompletionStatuses',
    'rideTypeIds',
    'search',
    'sort',
    'transportPartnerIds',
    'transportTypeIds',
    'zipcodeIds',
  ];

  @tracked accountIds: string[] = [];
  @tracked accountType: string | null = null;
  @tracked bookTimePeriod: string | null = null;
  @tracked bookTimeStart: string | null = null;
  @tracked bookTimeEnd: string | null = null;
  @tracked distanceIds: string[] = [];
  @tracked financialResponsibilities: string[] = [];
  @tracked geoId: string | null = null;
  @tracked isLoading = false;
  @tracked page = 1;
  @tracked regionIds: string[] = [];
  @tracked requestedStartTimePeriod: string | null = null;
  @tracked requestedStartTimeStart: string | null = null;
  @tracked requestedStartTimeEnd: string | null = null;
  @tracked rideCompletionStatuses: string[] = [];
  @tracked rideTypeIds: string[] = [];
  @tracked search: string = '';
  @tracked sort: string | null = null;
  @tracked transportPartnerIds: string[] = [];
  @tracked transportTypeIds: string[] = [];
  @tracked zipcodeIds: string[] = [];

  get filters() {
    const filters: Filters = {
      accountIds: this.accountIds,
      accountType: this.accountType as AccountType,
      bookTime: this.bookTimePeriod
        ? {
            datePeriod: this.bookTimePeriod,
            startDate: this.bookTimeStart,
            endDate: this.bookTimeEnd,
          }
        : null,
      distanceIds: this.distanceIds,
      financialResponsibilities: this.financialResponsibilities,
      geoId: this.geoId,
      regionIds: this.regionIds,
      requestedStartTime: this.requestedStartTimePeriod
        ? {
            datePeriod: this.requestedStartTimePeriod,
            startDate: this.requestedStartTimeStart,
            endDate: this.requestedStartTimeEnd,
          }
        : null,
      rideCompletionStatuses: this.rideCompletionStatuses,
      rideTypeIds: this.rideTypeIds,
      transportPartnerIds: this.transportPartnerIds,
      transportTypeIds: this.transportTypeIds,
      zipcodeIds: this.zipcodeIds,
    };
    return filters;
  }

  get options() {
    return { sort: this.sort } as AllRidesOptions;
  }

  @action
  filtersChanged(filters: Filters) {
    this.accountIds = filters.accountIds;
    this.accountType = filters.accountType;
    this.bookTimePeriod = filters.bookTime?.datePeriod ?? null;
    this.bookTimeStart = filters.bookTime?.startDate ?? null;
    this.bookTimeEnd = filters.bookTime?.endDate ?? null;
    this.distanceIds = filters.distanceIds;
    this.financialResponsibilities = filters.financialResponsibilities;
    this.geoId = filters.geoId;
    this.regionIds = filters.regionIds;
    this.requestedStartTimePeriod =
      filters.requestedStartTime?.datePeriod ?? null;
    this.requestedStartTimeStart =
      filters.requestedStartTime?.startDate ?? null;
    this.requestedStartTimeEnd = filters.requestedStartTime?.endDate ?? null;
    this.rideCompletionStatuses = filters.rideCompletionStatuses;
    this.rideTypeIds = filters.rideTypeIds;
    this.transportPartnerIds = filters.transportPartnerIds;
    this.transportTypeIds = filters.transportTypeIds;
    this.zipcodeIds = filters.zipcodeIds;
  }

  @action
  paramsChanged(params: AllRidesGridParams) {
    this.page = params.page ?? 1;
    this.sort = params.sort;
  }
}
